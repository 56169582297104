<template>
  <div class="blind offline">
    <div class="title backgroundWhite">
      <h1 class="h1">盲医考继续教育</h1>
    </div>
    <div class="blind_content offline_content">
      <div class="headers">
        <div class="tab backgroundWhite">
          <ul class="flex">
            <li v-for="(item,index) in tabs" :key="index" class="relative cursor" :class="{'active':active == index}"
                @click="list(index)">{{ item }} <i></i></li>
          </ul>
        </div>
        <div v-if="tableData.length >= 0">
          <div class="tab_content">
            <div class="offline_box text_C">
              <el-table
                  class="text_C"
                  :data="tableData"
                  stripe
                  style="width: 100%">
                <el-table-column
                    prop="batchtime"
                    fit
                    align="center"
                    label="期次">
                  <template slot-scope="scope">
                    <span>{{ scope.row.batchtime }}期</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    align="center"
                    width="180"
                    label="申请时间">
                </el-table-column>
                <el-table-column
                    align="center"
                    width="200"
                    label="培训时间">
                  <template slot-scope="scope">
                    <span>{{ scope.row.blindTrain.trainStart || '暂无时间' }}-{{
                        scope.row.blindTrain.trainEnd || '暂无时间'
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    align="center"
                    label="审核状态">
                  <template slot-scope="scope">
                    <span
                        :class="[{'blind_fc':scope.row.status == '0'},{'blind_red':scope.row.status == '2'},{'color66':scope.row.status == '1'}]">
                      <span v-if="scope.row.status==='0'">待审核</span>
                      <span v-if="scope.row.status==='1'">已通过</span>
                       <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top">
                        <span v-if="scope.row.status==='2'">未通过</span>
                       </el-tooltip>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="applyTable"
                    align="center"
                    class="relative"
                    v-if="false"
                    label="报名表">
                  <template slot-scope="scope">
                    <viewer :images="scope.row.applyTable">
                      <img :src="scope.row.applyTable" :key="scope.row.applyTable" class="cursor">
                      <span class="bs_text_oo cursor" v-if="scope.row.applyTable">
                        预览
                      </span>
                      <span class="bs_text_oo cursor" v-else>暂无报名表</span>
                    </viewer>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    class="line_clamp1"
                    width="200"
                    label="培训地址">
                  <template slot-scope="scope">
                    <span>{{ scope.row.blindTrain.trainAddress }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作">
                  <template slot-scope="scope">
                    <button class="b_btn offline_success relative"
                            :disabled="scope.row.status == '2'?false:true"
                            @click="goRouter(scope.row.id,scope.row.status,scope.row.blindTrain.trainLevel,'1')">编辑
                      <i></i>
                    </button>
                    <button class="b_btn offline_success"
                            @click="goRouter(scope.row.id,scope.row.status,scope.row.blindTrain.trainLevel,'0')">详情
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        class="work_pagination"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :prev-text="pagination.pre"
        :next-text="pagination.next"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  name: "blind",
  head() {
    return {
      title: '盲医考继续教育',
      meta: []
    }
  },
  data() {
    return {
      active: 0,
      tabs: ['国级培训', '省级培训'],
      disable: false,
      tableData: [],
      tableData1: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
        pre: "上一页",
        next: "下一页"
      },
      pagination1: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
        pre: "上一页",
        next: "下一页"
      },
      url: {
        list_country: '/blindTrainApply/list_country',
        list_province: '/blindTrainApply/list_province',
      },

      preview: ['https://img-blog.csdnimg.cn/20190927151124774.png']
    }
  },
  methods: {
    list: function (index) {
      this.active = index;
      if (index === 0) {
        this.list_country();
      } else if (index === 1) {
        this.list_province();
      }
    },

    list_country: function () {
      let that = this;
      this.$api.lanzhouList_countryApi({
        pageNo: that.pagination.pageNo,
        pageSize: that.pagination.pageSize
      }).then(result => {
        console.log(result);
        if (result.data.success) {
          that.tableData = result.data.data.records;
          that.pagination.total = result.data.data.total;
        } else {
          that.$message.error("查询盲按培训项目失败！");
        }
      })
    },
    list_province: function () {
      let that = this;
      this.$api.lanzhouList_provinceApi({
        pageNo: that.pagination.pageNo,
        pageSize: that.pagination.pageSize
      }).then(result => {
        console.log(result.data.data);
        if (result.data.success) {
          that.tableData = result.data.data.records;
          that.pagination.total = result.data.data.total;
        } else {
          that.$message.error("查询盲按培训项目失败！");
        }
      })
    },

    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.list_country();
    },
    //从新页面打开
    goRouter(id, status, level, call) {
      const {href} = this.$router.resolve({
        path: '/lanzhou/center/blind/blindEdit',
        query: {id: id, status: status, level: level, call: call}
      })
      window.open(href, "_self")
    }
  },
  mounted() {

  },
  created() {
    this.list(0);
  }
}
</script>

<style scoped lang="less">
//统一标题
.title {
  display: flex;
  height: 48px;
  align-items: center;
  margin-bottom: 10px;

  h1 {
    padding: 0 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}

.offline {
  .offline_content {
    margin-bottom: 20px;

    .headers {
      .tab {
        padding-left: 20px;

        ul {
          li {
            padding: 20px 30px;
            color: #666666;
          }

          li.active {
            color: #00924b;
          }

          li:first-child i {
            position: absolute;
            top: 40%;
            right: 0;
            width: 1px;
            height: 14px;
            border-right: 1px solid #999999;
          }

          li.active::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background: #00924b;
          }
        }
      }

      .input {
        .left, .right {
          flex: 1;
          padding: 20px;
        }

        .left {
          //transform: translateY(10px);
          span {
            color: #666666;
          }
        }
      }
    }

    .offline_box {
      .offline_success {
        color: #00924b;
        padding-right: 15px;
      }

      .offline_success i {
        position: absolute;
        top: 3px;
        right: 8px;
        width: 1px;
        height: 14px;
        background: #666666;
      }

      .offline_un {
        color: #666666;
        text-decoration: underline;
      }

      .offline_fc {
        color: #fc6c38;
      }

      .offline_gray {
        color: #666666;
      }
    }
  }
}

//盲医考继续教育
.blind {
  button:disabled {
    color: #666666 !important;
  }

  .blind_fc {
    color: #fc6c38;
  }

  .blind_red {
    color: #E70E0E;
  }
}

.el-table {
  .el-table__body-wrapper {
    .el-table__row {
      td {
        img {
          height: 32px;
          opacity: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
